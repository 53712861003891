import type { TeaserImageFormat } from '@hubcms/domain-images';
import type { TeaserImage } from '@hubcms/domain-teaser';

export type ImageDetails = {
  width: number;
  height: number;
  url: string;
  squareUrl: string;
  aspectRatio: TeaserImageFormat;
  caption: string;
};

export function getImageDetails(rawImage: TeaserImage, preferredImageFormat: TeaserImageFormat): ImageDetails {
  const image = rawImage.imageFormats[preferredImageFormat];
  return {
    width: image.width,
    height: image.height,
    url: image.url,
    squareUrl: rawImage.imageFormats.oneOne?.url ?? '',
    aspectRatio: preferredImageFormat,
    caption: rawImage.caption ?? '',
  };
}

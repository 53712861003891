import type { ImageFormat } from './image-format';

export const ImageAspectRatio: Record<Exclude<ImageFormat, 'baseWidth'>, { width: number; height: number }> = {
  fourFive: { width: 4, height: 5 },
  fourThree: { width: 4, height: 3 },
  oneOne: { width: 1, height: 1 },
  sixteenNine: { width: 16, height: 9 },
  threeTwo: { width: 3, height: 2 },
  twentyoneNine: { width: 21, height: 9 },
};

import { teaserLabelPropConfig } from '@hubcms/brand';
import type { ArticleTeaserData, TeaserLabelProp, TeaserLabelPropPerComponentType } from '@hubcms/domain-teaser';

export function getTeaserLabelProp(
  contentType: ArticleTeaserData['contentType'],
  component: keyof TeaserLabelPropPerComponentType,
): TeaserLabelProp {
  const teaserLabelProp = teaserLabelPropConfig[contentType] ?? teaserLabelPropConfig.default;
  return typeof teaserLabelProp === 'string' ? teaserLabelProp : teaserLabelProp[component] || teaserLabelProp.default;
}
